export default class Usuario {
  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.tipo = data.tipo;
      this.idPerfil = data.idPerfil;
      this.nomPerfil = data.nomPerfil;
      this.indRecebeMail = data.indRecebeMail;
      this.indAtivo = data.indAtivo;
      this.indUsuarioExternoMaster = data.indUsuarioExternoMaster;
      this.idEstruturaUsuario = data.idEstruturaUsuario;
      this.nomEstruturaUsuario = data.nomEstruturaUsuario;
      this.idGrupoFornecedor = data.idGrupoFornecedor;
      this.nomGrupoFornecedor = data.nomGrupoFornecedor;
      this.indAprovaWorkflow = data.indAprovaWorkflow;
      this.nomUsuario = data.nomUsuario;
      this.nome = data.nome;
      this.nomeSobrenome = data.nomeSobrenome;
      this.sobrenome = data.sobrenome;
      this.email = data.email;
      this.matricula = data.matricula;
      this.idExterno = data.idExterno;
      this.cpf = data.cpf;

      this.idDivisoes = data.idDivisoes;
      this.superioresImediatos = data.superioresImediatos;
      this.clientes = data.clientes;
      this.tiposVerba = data.tiposVerba;
      this.desAtributos = Usuario.montarDesAtributos(data);
    } else {
      this.tipo = 'FUNCIONARIO';
      this.indRecebeMail = false;
      this.indAprovaWorkflow = false;
      this.indAtivo = true;
      this.idDivisoes = [];
      this.superioresImediatos = [];
      this.clientes = [];
      this.tiposVerba = [];
      this.canais = [];
      this.documento = null;
    }
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.tipo = dadosDaConsulta.tipo;
    this.idPerfil = dadosDaConsulta.idPerfil;
    this.nomPerfil = dadosDaConsulta.nomPerfil;
    this.indRecebeMail = dadosDaConsulta.indRecebeMail;
    this.indAtivo = dadosDaConsulta.indAtivo;
    this.indUsuarioExternoMaster = dadosDaConsulta.indUsuarioExternoMaster;
    this.idEstruturaUsuario = dadosDaConsulta.idEstruturaUsuario;
    this.nomEstruturaUsuario = dadosDaConsulta.nomEstruturaUsuario;
    this.idGrupoFornecedor = dadosDaConsulta.idGrupoFornecedor;
    this.nomGrupoFornecedor = dadosDaConsulta.nomGrupoFornecedor;
    this.indAprovaWorkflow = dadosDaConsulta.indAprovaWorkflow;
    this.nomUsuario = dadosDaConsulta.nomUsuario;
    this.nome = dadosDaConsulta.nome;
    this.nomeSobrenome = dadosDaConsulta.nomeSobrenome;
    this.sobrenome = dadosDaConsulta.sobrenome;
    this.email = dadosDaConsulta.email;
    this.matricula = dadosDaConsulta.matricula;
    this.idExterno = dadosDaConsulta.idExterno;
    this.clientes = dadosDaConsulta.clientes;
    this.tiposVerba = dadosDaConsulta.tiposVerba;
    this.cpf = dadosDaConsulta.cpf;
    this.cargo = dadosDaConsulta.cargo;
    this.documento = dadosDaConsulta.documento;

    this.divisoes = Usuario.parsearCampos(dadosDaConsulta.objDivisoes, 'nome');
    this.idDivisoes = this.divisoes.map((item) => item.id);
    this.relatUsuario = dadosDaConsulta.relatUsuario;
    this.superioresImediatos = Usuario.parsearCampos(dadosDaConsulta.superioresImediatos, 'nomeCompleto');
    this.desAtributos = dadosDaConsulta.desAtributos;
  }

  static parsearCampos(stringComAtributos, atributo) {
    if (!stringComAtributos) {
      return [];
    }

    return stringComAtributos.split(';;').map((umElementoEmString) => {
      const arrayComAtributos = umElementoEmString.split('|');
      const retorno = {};
      const [id, valor] = arrayComAtributos;
      retorno.id = parseInt(id, 10);
      retorno[atributo] = valor;
      return retorno;
    });
  }

  ehEdicao() {
    return this.id;
  }

  static montarDesAtributos(dados) {
    dados.desAtributos = dados.desAtributos
    && dados.desAtributos.value
      ? JSON.parse(dados.desAtributos.value) : {};

    if (!dados.desAtributos.regional) {
      return { regional: false };
    }

    return { ...dados.desAtributos };
  }
}
